<template>

  <div 
    data-testid="profile-options-container"
    class="w-full px-4"
  >
    <s-list-item
      :primary-text="translate('AppContainer.ModifyUserData')"
      :title="translate('AppContainer.ModifyUserData')"
      @item-click="openProfileUrl(ProfileOption.MANAGE_USER_DATA)"
    />
    <s-list-item
      :primary-text="translate('AppContainer.ChangePassword')"
      :title="translate('AppContainer.ChangePassword')"
      @item-click="openProfileUrl(ProfileOption.CHANGE_PASSWORD_URL)"
    />
  </div>

</template>

<script setup lang="ts">
import { useAppStore } from '@/store';
import { SListItem } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { reactive } from 'vue';


const store = useAppStore()

const ProfileOption = reactive({
  CHANGE_PASSWORD_URL : store.urls.changePasswordUrl ?? '',
  MANAGE_USER_DATA: store.urls.manageUserDataUrl ?? ''
})

const { translate } = useTranslator()

const openProfileUrl = (url: string):void => {
  if (url) window.open(url, '_self')
}

</script>