import { createRouter, createWebHashHistory, type Router } from 'vue-router'
import { AppView, ErrorView, CallbackView, LandingView } from '@/views/'
import { ROUTE_CALLBACK, ROUTE_HOME, ROUTE_MAIN, ROUTE_UNAUTHORIZED } from '@/helpers/constants'
import { useAuthentication } from 'sima-suite-authentication'

export const getRouter = (checkUserAuthentication: any): Router => {
  return createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: ROUTE_MAIN,
        component: AppView,
        redirect: { name: ROUTE_HOME },
        beforeEnter: async (_from, _to, next) => {
          const isLoggedIn = await checkUserAuthentication()
          if (isLoggedIn) {
            next()
          } else {
            const auth = useAuthentication()
            await auth
              .signIn()
              .then(() => next())
              .catch(() => {
                next({ name: ROUTE_UNAUTHORIZED })
              })
          }
        },
        children: [
          {
            path: '/home',
            name: ROUTE_HOME,
            component: LandingView
          }
        ]
      },
      {
        path: '/callback',
        name: ROUTE_CALLBACK,
        component: CallbackView
      },
      {
        path: '/unauthorized',
        name: ROUTE_UNAUTHORIZED,
        component: ErrorView,
        beforeEnter: async (_from, _to, next) => {
          const isLoggedIn = await checkUserAuthentication()
          if (isLoggedIn) {
            next({ name: ROUTE_MAIN })
          } else next()
        }
      }
    ]
  })
}
