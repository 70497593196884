import { defineStore } from 'pinia'
import type { UserState } from './types'
import { useAuthentication } from 'sima-suite-authentication'

export const useUserStore = defineStore({
  id: 'userStore',
  state: (): UserState => ({
    user: {}
  }),
  getters: {
    getUser(): {} {
      return this.user
    },
    isLogged(): boolean {
      return this.user && Object.keys(this.user).length > 0
    }
  },
  actions: {
    getUserProfile(): void {
      const { getUserProfile } = useAuthentication()
      this.user = getUserProfile()
    },
    logout(): void {
      const { logout } = useAuthentication()
      logout().then(() => {
        this.user = {}
      })
    },
    async signIn(): Promise<void> {
      const { signIn } = useAuthentication()
      await signIn()
    }
  }
})
export type UsersStoreType = ReturnType<typeof useUserStore>
