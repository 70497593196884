import { Integration } from '@sentry/types'
import {
  init,
  captureException,
  captureMessage,
  Replay,
  BrowserTracing,
  vueRouterInstrumentation
} from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'
export class Release {
  constructor(
    private name: string,
    private version: string
  ) {}

  getReleaseName = (): string => `${this.name}@${this.version}`
}

export interface MonitoringConfig {
  app: App<Element>
  release: Release
  key: string
  url: string
  productionEnvironments: string[]
  environment: string
  devMode?: boolean
  router: Router
}

export function initMonitoring(config: MonitoringConfig) {
  const { release, key, url, productionEnvironments, environment, devMode, app, router } = config
  if (productionEnvironments.includes(environment) || devMode) {
    init({
      release: release.getReleaseName(),
      dsn: key,
      app,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [
        new Replay(),
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router)
        }) as unknown as Integration
      ],
      trackComponents: true,
      logErrors: true,
      environment: environment,
      tracePropagationTargets: [url]
    })
  }
}

export type LogLevel = 'debug' | 'warning' | 'log' | 'info'
export type ErrorLevel = 'error' | 'fatal'

export const logger = {
  logError(error: Error, level: ErrorLevel = 'error') {
    captureException(error, { level })
  },
  logMessage(message: string, level: LogLevel = 'debug') {
    captureMessage(message, { level })
  }
}
