import { provideAuthentication } from 'sima-suite-authentication'
import type { Settings } from './types'
import { defaultWorkspaceApps } from '@/mocks/index'

export async function loadConfiguration() {
  const headers = {
    'Content-type': 'application/json; charset=UTF-8',
    Authorization: `Bearer ${getToken()}`
  }
  provideAuthentication({
    authority: '',
    clientId: '',
    redirectLogin: '',
    redirectLogout: '',
    silentRenew: '',
    scope: ''
  })
  if (import.meta.env.DEV) return configureDevelopmentSettings()

  //TODO Remove parameter when backend changes always returning sima desktop apps
  const request = await fetch(`./frontendconfiguration.json?showsimadesktop=true`, { headers })
  return await request.json()
}

function getToken() {
  let token = ''

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i)

    if (key && key.startsWith('oidc.user:') && key.endsWith(':sima-workspace')) {
      token = JSON.parse(sessionStorage[key])?.access_token
      break
    }
  }

  return token
}

function configureDevelopmentSettings(): Settings {
  const env = import.meta.env
  return {
    backendUrl: env.VITE_BACKEND_URL,
    sentryKey: env.VITE_SENTRY_KEY,
    googleAnalyticsKey: env.VITE_GOOGLE_ANALYTICS,
    poweredByUrl: env.VITE_POWERED_BY_URL,
    helpUrl: env.VITE_HELP_URL,
    newsletterUrl: env.VITE_NEWSLETTER_URL,
    authentication: {
      authority: env.VITE_AUTH_AUTHORITY,
      clientId: env.VITE_AUTH_CLIENT_ID,
      redirectLogin: env.VITE_AUTH_REDIRECT_LOGIN,
      redirectLogout: env.VITE_AUTH_REDIRECT_LOGOUT,
      silentRenew: env.VITE_AUTH_SILENT_RENEW,
      scope: env.VITE_AUTH_SCOPE
    },
    workspaceApps: defaultWorkspaceApps,
    manageUserDataUrl: env.VITE_MANAGE_USER_DATA_URL,
    changePasswordUrl: env.VITE_CHANGE_PASSWORD_URL,
    productFruitKey: env.VITE_PRODUCT_FRUIT_URL
  }
}
