<template>
  <div class="flex flex-col justify-center items-center">
    <button
      type="button"
      class="icon-container cursor-pointer w-28 h-28 xl:w-32 xl:h-32 p-4 xl:p-6 flex justify-center items-center"
      :aria-label="appData.name"
      :data-testid="`${appData.name}-button`"
      @click="clickOnApp"
    >
      <inline-svg
        class="w-10/12 h-5/6"
        role="app-icon"
        :alt="appData.name"
        :src="getLogoUrl()"
      />
    </button>
    <span
      class="image-text mt-2"
    >
      {{ appData.name }}
    </span>
  </div>
</template>
  
<script setup lang="ts">
import type { WorkspaceApp } from '@/types.ts'
import { event } from 'vue-gtag'
import { useAuthentication } from 'sima-suite-authentication'
import InlineSvg from 'vue-inline-svg' 
import { computed, ref } from 'vue'
import { IconColors } from './types'

const props = defineProps<{
  appData: WorkspaceApp
}>() 

const authenticationClient = useAuthentication()

const customGradient = computed(()=>{
  const product = (props.appData.icon.toUpperCase() ?? 'DEFAULT') as keyof typeof IconColors
  return IconColors[product]?.split(' ')
})

const halfOpacityGradient = ref(`${customGradient.value[0]}80`)

const getLogoUrl = (()=>{
  let path = new URL('/appsIcons', import.meta?.url)
  return `${path}/ic_${props.appData.icon}.svg`
})

const getAppUrl = (()=>{
  let path = props.appData.url
  if(props.appData?.isSimaDesktop || props.appData?.isSimaVirtualized) path += `//authToken/${authenticationClient.getAccessToken()}`
  return path
})

const clickOnApp = (()=>{
  // Metrics
  event(`click_${props.appData.name.toLowerCase()}`, { event_category: 'application' })
  window.open(getAppUrl(), '_blank')
})
  
</script>
  
<style scoped>

  .icon-container {
    border-radius: 3rem;
    border: 0.375rem solid transparent;
    background-image: linear-gradient(to right, v-bind('customGradient[0]'), v-bind('customGradient[1]'));
    background-clip: padding-box;
  }

  .icon-container:hover {
    border: 0.375rem solid v-bind('halfOpacityGradient');
    animation: border-pulsate 0.7s;
  }

.image-text {
    max-width: 11rem;
    color: #303030;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
} 

@keyframes border-pulsate {
    0%   { border-color: transparent }
    100%   { border-color: v-bind('halfOpacityGradient') }
  }
  </style>