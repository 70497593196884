import { defineStore } from 'pinia'
import type { AppState, requiredUrls } from './types'
import type { WorkspaceApp } from '@/types.ts'

export const useAppStore = defineStore({
  id: 'appStore',
  state: (): AppState => ({
    apps: [],
    urls: {
      poweredByUrl: '',
      helpUrl: '',
      newsletterUrl: '',
      changePasswordUrl: '',
      manageUserDataUrl: '',
      productFruitKey: ''
    }
  }),
  actions: {
    async init(apps: WorkspaceApp[] | undefined, urls: requiredUrls) {
      try {
        this.apps = apps ?? []
        this.urls = {
          poweredByUrl: urls.poweredByUrl ?? '',
          helpUrl: urls.helpUrl ?? '',
          newsletterUrl: urls.newsletterUrl ?? '',
          changePasswordUrl: urls.changePasswordUrl ?? '',
          manageUserDataUrl: urls.manageUserDataUrl ?? '',
          productFruitKey: urls.productFruitKey ?? ''
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
})
export type useAppStore = ReturnType<typeof useAppStore>
