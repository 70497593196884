<template
  class="error-container"
>
  <s-visual-context 
    :title="translate('Error.View.Text')"
    :subtitle="translate('Error.View.Subtext')"
    :image="image"
  />
</template>
<script setup lang="ts">
import { SVisualContext } from 'sima-suite-components-vue3'
import errorImg from '@/assets/errorImg.png'
import { useTranslator } from 'sima-suite-translator-vue3'
const { translate } = useTranslator()

const image = {
  src: errorImg,
  alt: 'Error'
}

</script>
<style scoped>
.error-container :deep() img{
  max-width: 20rem;
  width: 20rem;
}
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
